
import { computed, defineComponent, PropType } from "vue";
import TooltipStatus from "@/components/TooltipStatus.vue";
import { useActor } from "@xstate/vue";
import type { BookMachineActor } from "@/machines/bookMachine";

export default defineComponent({
  props: {
    bookRef: {
      type: Object as PropType<BookMachineActor>,
      required: true,
    },
  },
  setup(props) {
    const { state: bookState } = useActor(props.bookRef);
    const book = computed(() => bookState.value.context?.book);
    const buttons = computed(() => bookState.value.context?.buttons);
    const isLoading = computed(() => bookState.value.matches("loadBook"));

    return { book, bookState, buttons, isLoading };
  },
  components: {
    TooltipStatus,
  },
});
