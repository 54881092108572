
import { computed, defineComponent, PropType } from "vue";
import type { item } from "@/types";
import BookRow from "@/components/BookRow.vue";
import { listMachine } from "@/machines/listMachine";
import { useMachine } from "@xstate/vue";

export default defineComponent({
  props: {
    filter: {
      type: Function as PropType<(i: item) => boolean>,
      required: true,
    },
  },
  setup(props) {
    const { state: listState, send: sendList } = useMachine(listMachine);
    sendList({ type: "CLICK" });
    const isLoading = computed(() =>
      (["loading", "idle"] as ["loading", "idle"]).some(listState.value.matches)
    );
    const bookRefs = computed(() => listState.value.context.result);

    const filtteredBookRefs = computed(() =>
      bookRefs.value.filter((ref) => props.filter(ref.state.context.listItem))
    );

    return { filtteredBookRefs, bookRefs, isLoading };
  },
  components: {
    BookRow,
  },
});
